import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { pageContext } from '../../../../types'
import SEO from '~/utils/seo'
import { TimesPage } from '~/components/pages/TimesPage'

export default function OnsenTimesKeywordPage({ location, data, pageContext }: PageProps<GatsbyTypes.OnsenTimesCategoryPageQuery, pageContext & { keyword: string, keywordId: string }>) {
    return (
        <>
            <SEO
                title={`${pageContext.keyword}のニュース・記事 | Onsen*`}
                description='Onsen*のサービスや製品に関する最新情報やメディア掲載情報、イベント情報やお風呂にまつわる記事など多種多様なニュースを掲載しています。'
                robots='all'
                image={data.allMicrocmsNews.edges[0]?.node?.thumbnail?.url}
                url={location.href}
            />
            <TimesPage data={data} pageContext={pageContext} pathName={`/times/${pageContext.keywordId}`} tagName={pageContext.keyword}  />
        </>
    )
}

export const query = graphql`
    query OnsenTimesKeywordPage($keywordId: String!, $skip: Int!, $limit: Int!) {
        allMicrocmsNews(filter: {keyword: {elemMatch: {id: {eq: $keywordId}}}}, skip: $skip, limit: $limit, sort: {fields: postDate, order: DESC}) {
            edges {
                node {
                    title
                    contents
                    newsId
                    thumbnail {
                        url
                    }
                    publishedAt(formatString: "YYYY.MM.DD")
                    postDate(formatString: "YYYY.MM.DD")
                }
            }
        }
    }
`
